import {
    HydrationBoundary,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { loadAndActivateI18n } from './i18n';
import Ads from './ads/Ads';

const wrapper = document.querySelector(`recommendation-podlet-isolated`);

if (wrapper?.shadowRoot) {
    const data = wrapper.shadowRoot.querySelector(
        `script[type="application/json"]`,
    );
    if (data && data.textContent) {
        const { reactQueryState, ...props } = JSON.parse(data.textContent);
        const queryClient = new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                },
            },
        });
        loadAndActivateI18n(props.locale);
        const element = wrapper.shadowRoot.querySelector(
            `#recommendation-podlet`,
        );
        if (element) {
            hydrateRoot(
                element,
                <QueryClientProvider client={queryClient}>
                    <HydrationBoundary state={reactQueryState}>
                        <Ads {...props} />
                    </HydrationBoundary>
                </QueryClientProvider>,
            );
        }
    }
}

import React, { useMemo, useRef, useState } from 'react';
import { Item, RecommendationData } from '../data/RecommendationDataTypes';
import {
    useAdPositionElements,
    insertBanners,
} from '@schibsted-nmp/advertising-companion';
import { useInfiniteFetchMoreData } from '../data/RecommendationDataHooks';
import Ad from './Ad';
import PulseTracking from '../tracking/pulseTracking';
import { usePrefetchRecommendationDataQuery } from '../data/RecommendationData';
import { useBottomReached } from '../tracking/bottomReachedTracking';
import { Button } from '@warp-ds/react';
import { getAdsTitle, shouldShowLoadMoreButton } from '../utils/adsUtils';

type AdsProps = {
    cdnBaseUrl: string;
    apiUrl: string;
    loginId: string;
    locale: string;
    position: string;
    favoriteBaseUrl: string;
    recommender: string;
    adId: number;
    numColumns: number;
    rowsToRender: number;
};

export default function Ads(props: AdsProps) {
    const {
        cdnBaseUrl,
        apiUrl,
        loginId,
        locale,
        position,
        favoriteBaseUrl,
        recommender,
        adId,
        numColumns,
        rowsToRender,
    } = props;

    const [renderAllRows, setRenderAllRows] = useState(
        rowsToRender === 0 || !rowsToRender,
    );

    const [bannerPositions, bannerElements] = useAdPositionElements({
        type: 'recommendation',
        layoutType: 'grid',
    });

    const nodeRef = useRef<HTMLDivElement>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

    const { recommendationData: initialData, isFetched: initialIsFetched } =
        usePrefetchRecommendationDataQuery();

    const fetchMoreUrlsPaged = renderAllRows
        ? initialData?.fetchMore
        : initialData?.fetchMore.slice(0, rowsToRender);

    const { currentFetchMoreData, isFetched, hasMoreFetchData } =
        useInfiniteFetchMoreData({
            apiUrl,
            ref: nodeRef,
            recommender,
            position,
            fetchMoreUrls: fetchMoreUrlsPaged,
        });

    const tracking = useMemo(
        () =>
            new PulseTracking({
                position,
                trackingContext: initialData?.['tracking-context'] ?? {},
            }),
        [position, initialData],
    );

    const elements = initialData?.items?.map((item: Item) => (
        <Ad
            key={item.itemId}
            item={item}
            cdnBaseUrl={cdnBaseUrl}
            loginId={loginId}
            locale={locale}
            tracking={tracking}
            favoriteBaseUrl={favoriteBaseUrl}
            inscreenTrackingIsEnabled={initialIsFetched}
        />
    ));

    const fetcMoreElements = currentFetchMoreData?.pages?.flatMap(
        (page: RecommendationData) =>
            page?.items?.map((item: Item) => (
                <Ad
                    key={item.itemId}
                    item={item}
                    cdnBaseUrl={cdnBaseUrl}
                    loginId={loginId}
                    locale={locale}
                    tracking={tracking}
                    favoriteBaseUrl={favoriteBaseUrl}
                    inscreenTrackingIsEnabled={isFetched}
                />
            )),
    );

    const allElements = elements ? [...elements, ...fetcMoreElements] : [];

    function getOikotieFragment() {
        return (
            <React.Fragment key={`ad-item-5`}>
                <iframe
                    ref={iframeRef}
                    src={`https://recommendations.asunnot.oikotie.fi/tori-aurora-front-page.html`}
                    className={`relative isolate recommendation-ad card card--cardShadow s-bg`}
                    width="100%"
                    height="100%"
                />
            </React.Fragment>
        );
    }

    let adItems = allElements;

    if (position?.includes('front')) {
        // Split the elements.
        const elementsPart1 = allElements.slice(0, 4);
        const elementsPart2 = allElements.slice(4, allElements.length);

        // Insert the okiotie ad.
        adItems = [...elementsPart1, getOikotieFragment(), ...elementsPart2];
    }

    const adsWithBanners = insertBanners({
        adItems,
        bannerPositions,
        bannerElements,
    });

    let adsTitle = '';

    if (initialIsFetched) {
        adsTitle = getAdsTitle(adId, initialData, elements?.length);
    }

    const { bottomRef, isBottomReached } = useBottomReached({
        onBottomReachedFn: () => {
            if (!hasMoreFetchData) {
                fetch(`${apiUrl}/tracking`);
            }
        },
    });

    return (
        <div>
            <h1 className="text-m">{adsTitle}</h1>
            <div
                className={`grid f-grid grid-flow-row-dense grid-cols-2 ${
                    numColumns === 5 ? 'lg:grid-cols-5' : 'lg:grid-cols-3'
                } gap-10`}
            >
                {adsWithBanners}
                <div ref={nodeRef} />
                <div ref={bottomRef} style={{ height: '1px' }}></div>
            </div>
            {shouldShowLoadMoreButton(
                renderAllRows,
                isBottomReached,
                hasMoreFetchData,
            ) && (
                <div className="text-center">
                    <Button
                        primary
                        className="my-16 leading-m"
                        onClick={() => {
                            setRenderAllRows(true);
                        }}
                        ref={loadMoreButtonRef}
                    >
                        {'Se flere anbefalinger'}
                    </Button>
                </div>
            )}
        </div>
    );
}

import { i18n } from '@lingui/core';

export function getAdsTitle(
    adId: number,
    initialData: any,
    elementsLength: number,
): string {
    if (elementsLength > 0) {
        if (adId) {
            return i18n.t({
                id: 'ads.title.mlt',
                message: 'More like this',
            });
        } else if (initialData?.isPersonal) {
            return i18n.t({
                id: 'ads.title.personal',
                message: 'Personalized recommendations',
            });
        } else {
            return i18n.t({
                id: 'ads.title.popular',
                message: 'Popular ads',
            });
        }
    }
    return '';
}

export function shouldShowLoadMoreButton(
    renderAllRows: boolean,
    isBottomReached: boolean,
    hasMoreFetchData: boolean,
): boolean {
    return !renderAllRows && isBottomReached && !hasMoreFetchData;
}

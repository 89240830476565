import { useEffect, useState } from 'react';
import { useInfiniteScrollFetchMoreData } from './RecommendationData';
import { RecommendationData } from './RecommendationDataTypes';
import { InfiniteData } from '@tanstack/react-query';
import * as React from 'react';
import { useInscreenObserver } from '../useInscreenObserver';

type InfiniteScrollFetchMoreDataProps = {
    apiUrl?: string;
    ref?: React.MutableRefObject<Element | null>;
    recommender?: string;
    position?: string;
    fetchMoreUrls?: string[];
};

export function useInfiniteFetchMoreData(
    props: InfiniteScrollFetchMoreDataProps,
) {
    const { apiUrl, ref, recommender, position, fetchMoreUrls } = props;
    const {
        fetchMoreData,
        isPersonal,
        fetchNextPage,
        isFetched,
        hasMoreFetchData,
    } = useInfiniteScrollFetchMoreData(
        apiUrl,
        recommender,
        position,
        fetchMoreUrls,
    );
    const [currentFetchMoreData, setCurrentFetchMoreData] = useState<
        InfiniteData<RecommendationData>
    >({
        pages: [],
        pageParams: [],
    });

    useEffect(
        function updateCurrentFetchMoreData() {
            if (fetchMoreData !== null && fetchMoreData !== undefined) {
                setCurrentFetchMoreData(fetchMoreData);
            }
        },
        [setCurrentFetchMoreData, fetchMoreData],
    );

    useInscreenObserver(
        ref,
        () => {
            fetchNextPage();
        },
        {
            threshold: 0,
            once: true,
        },
    );

    return {
        currentFetchMoreData,
        isPersonal,
        isFetched,
        hasMoreFetchData,
    };
}

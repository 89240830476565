import React, { useRef, useState } from 'react';
import { Item } from '../data/RecommendationDataTypes';
import createHeartComponent from '@finn-no/favorite-heart-component';
import PulseTracking from '../tracking/pulseTracking';
import { useTrackInscreen } from '../tracking/trackingHooks';
import { IconShipping16 } from '@warp-ds/icons/react/shipping-16';

type AdProps = {
    item: Item;
    cdnBaseUrl: string;
    loginId: string;
    locale: string;
    tracking: PulseTracking;
    favoriteBaseUrl: string;
    inscreenTrackingIsEnabled: boolean;
};

const Ad: React.FC<AdProps> = ({
    item,
    cdnBaseUrl,
    loginId,
    locale,
    tracking,
    favoriteBaseUrl,
    inscreenTrackingIsEnabled,
}) => {
    const domRef = useRef<HTMLDivElement>(null);

    const wcRef =
        typeof document !== 'undefined'
            ? document
                  .querySelector('recommendation-podlet-isolated')
                  ?.shadowRoot.getElementById('recommendation-podlet')
            : null;

    const Heart = createHeartComponent({
        baseUri: favoriteBaseUrl,
        userId: loginId,
        renderServerSide: true,
        modalAttach: wcRef ? wcRef : null,
    });

    const handleAdClick = (event) => {
        event.preventDefault();
        tracking.trackClickWithPulse(item.tracking?.pulse);
        setTimeout(() => {
            window.location.href = `/${item.itemId}`;
        }, 200);
    };

    const itemIdNumber: number = Number(item.itemId);
    const [hasBeenTracked, setHasBeenTracked] = useState(false);

    useTrackInscreen({
        tracking,
        ref: domRef,
        pulseContext: item?.tracking?.pulse,
        enabled: inscreenTrackingIsEnabled,
        hasBeenTracked,
        setHasBeenTracked,
    });

    const shippingLabel = item.shippingOption?.label;

    const p4pLabel = item.overlay?.p4p?.label;
    const easyapplyLabel = item.overlay?.easyapply?.label;

    if (p4pLabel) {
        console.log(p4pLabel);
    }
    if (easyapplyLabel) {
        console.log(easyapplyLabel);
    }

    return (
        <article
            className="relative isolate recommendation-ad card card--cardShadow s-bg"
            ref={domRef}
        >
            {/* image  wrapper */}
            <div className="aspect-square overflow-hidden rounded-8 border">
                {/* image */}
                <img
                    alt=""
                    className={
                        'w-full h-full object-center object-cover m-auto'
                    }
                    sizes="(min-width: 1300px) 303px, (min-width: 1010px) 313px, (min-width: 768px) 33vw, (min-width: 480px) 50vw, 100vw"
                    src={`${cdnBaseUrl}/${item.image.url}`}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }}
                />
                {/* label */}
                <span>
                    <div
                        className="absolute bottom-0 font-bold s-text-inverted-subtle py-10 pl-10 pr-16 custom-label-style"
                        title="Legg til favoritt"
                    >
                        {item.label}
                    </div>
                </span>
            </div>

            <div className="text-s s-text-subtle mx-16 mt-8">
                <span className="nonEmpty">{item.location?.combined}</span>
            </div>

            <div className="mx-16 mt-6 mb-14">
                <h2 className="h4 mb-0 break-words" id={item.itemId}>
                    <a
                        className="recommendation-ad-link s-text! hover:no-underline"
                        href={`/${item.itemId}`}
                        id={item.itemId}
                        onClick={handleAdClick}
                    >
                        <span
                            className="absolute inset-0"
                            aria-hidden="true"
                        ></span>
                        {item.heading}
                    </a>
                </h2>
            </div>

            <div className="absolute top-16 right-16">
                <Heart
                    variant="heart-on-image"
                    itemType="Ad"
                    locale={locale as 'en' | 'nb' | 'fi'}
                    itemId={itemIdNumber}
                    isMobile={false}
                    renderNumFavs={false}
                />
            </div>

            {shippingLabel && (
                <span className="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-warning-subtle-hover">
                    <IconShipping16 className="w-16 h-16 mr-4" />
                    {shippingLabel}
                </span>
            )}

            <div className="absolute inset-0 z-10 pointer-events-none outline-none rounded-8 border-2 border-transparent transition-all recommendation-ad-outline"></div>
        </article>
    );
};

export default Ad;

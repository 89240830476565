import InscreenObserver from '@finn-no/inscreen-observer';
import React, { useEffect } from 'react';
import PulseTracking from './pulseTracking';
import { PulseRecommendation } from '../data/RecommendationDataTypes';

type TrackInscreenProps = {
    tracking: PulseTracking;
    ref: React.MutableRefObject<HTMLDivElement>;
    pulseContext?: PulseRecommendation;
    enabled: boolean;
    hasBeenTracked: boolean;
    setHasBeenTracked: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useTrackInscreen = ({
    tracking,
    ref,
    enabled = true,
    pulseContext,
    hasBeenTracked,
    setHasBeenTracked,
}: TrackInscreenProps) => {
    useEffect(() => {
        if (enabled && hasBeenTracked === false) {
            const observer = new InscreenObserver.default(
                () => {
                    tracking.trackInscreenWithPulse(pulseContext);
                    setHasBeenTracked(true);
                },
                { once: true }, //looks like this once method is not working correctly
            );
            observer.observe(ref.current);
            return () => observer.disconnect();
        }
    }, [
        ref,
        tracking,
        enabled,
        pulseContext,
        hasBeenTracked,
        setHasBeenTracked,
    ]);
};
